import React, { Component } from 'react';
import { UserContext } from 'contexts/UserContext';
import { FiUser, FiLock } from 'react-icons/fi';
import { Navigate } from 'react-router-dom';
import logo from 'assets/logo.png'

import './Login.scss';

class Login extends Component {
	static contextType = UserContext;
	state = {
		username: '',
		password: '',
		loading: false,
		type: 'login',
	}


	handleSubmit = async (event) => {
		event.preventDefault()
		let { username, password } = this.state

		if (this.state.type != 'login') {
			this.setState({ ...this.state, loading: true })
			let recover = await this.context.forgotPassword(username)
			this.setState({ ...this.state, loading: false })
		} else {
			this.setState({ ...this.state, loading: true })
			let login = await this.context.logUserIn(username, password)
			console.log("login: ", login)
			this.setState({ ...this.state, loading: false })
		}
	}

	changeType = event => {
		event.preventDefault()
		let { type } = this.state
		if (type === 'login') {
			type = 'recover'
		} else {
			type = 'login'
		}
		this.setState({ ...this.state, type })
	}

	handleInput = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		})
	}
	componentDidMount() {
		document.title = 'Login to continue...';
		if (window.location.hostname == 'localhost') {
			this.setState({ ...this.state, username: '', password: '' })
		}
	}

	render() {
		document.title = "Welcome! Login to continue..."
		if (this.context.isLoggedIn) {
			return (
				<Navigate to="/dashboard" />
			)
		}
		return (
			<div id="login_page">
				<form method="post" onSubmit={this.handleSubmit} className="login_form">
					<img src={logo} className="logo" alt="logo" />

					<label className="username_label">
						<FiUser strokeWidth="2" />
						<input
							type="text"
							name="username"
							required={true}
							placeholder="Username or Email"
							onChange={this.handleInput}
							value={this.state.username}
						/>
					</label>

					{(this.state.type === 'login') &&
						<label className="username_label">
							<FiLock strokeWidth="2" />
							<input
								type="password"
								name="password"
								placeholder="Password"
								required={true}
								onChange={this.handleInput}
								value={this.state.password}
							/>
						</label>}
					<input type="submit" name="submit" value={(this.state.type === 'login') ? 'LOGIN' : 'RESET PASSWORD'} disabled={this.state.loading} />

					<a href="#" onClick={this.changeType} className="forgot_link">
						{(this.state.type === 'login') ? 'Forgot Password?' : "Back to login"}

					</a>
				</form>
			</div>
		)
	}
}

export default Login;
