import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { toastOptions, sort, dateFormat, currencyFormat } from 'utils/utils.jsx';
import { UserContext } from 'contexts/UserContext';
const DashboardContext = React.createContext();

class DashContextProvider extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			user: {},
			dashboardData: {
				quotesCount: 0,
				jobsCount: 0,
				invoicesCount: 0,
				paidInvoicesCount: 0,
				outofStockCount: 0,
				customersCount: 0,
				categoriesCount: 0,
				itemsCount: 0,

				unpaidInvoices: [],
				outofStockJobs: [],
				recentActivities: [],
				recentMessages: [],
				ChartData: {
					quotes: [],
					invoices: [],
					startDate: new Date().toISOString()
				}
			},
			activities: [],
			messages: [],
			reports: [],
			sidebarCounts: [],
			reduceQuoteCount: this.reduceQuoteCount,
			loadSideBar: this.loadSideBar,
			loadDashboardData: this.loadDashboardData,
			requestHelp: this.requestHelp,
			deleteActivity: this.deleteActivity,
			loadAllActivities: this.loadAllActivities,
			unloadAllActivities: this.unloadAllActivities,
			logUserOut: this.logUserOut,
			getUser: this.getUser,
			deleteMessage: this.deleteMessage,
			sendMessage: this.sendMessage,
			loadAllMessages: this.loadAllMessages,
			unloadAllMessages: this.unloadAllMessages,
			loadReports: this.loadReports,
			unloadReports: this.unloadReports,
		}
	}

	logUserOut = async () => {
		this.context.logUserOut();
	}

	getUser = async () => {

		this.setState({ ...this.state, user: this.context.user });

	}

	reduceQuoteCount = async () => {
		this.setState({ ...this.state, sidebarCounts: { ...this.state.sidebarCounts, Quotes: this.state.sidebarCounts.quotes - 1 } });
	}



	loadSideBar = async () => {
		fetch('/api/dashboard/sidebar')
			.then(async (req) => {
				let res = await req.json()
				this.setState({ ...this.state, sidebarCounts: res.data[0] })

			})
			.catch(e => {
				console.log(e)
			})
	}

	componentDidMount() {
		if (this.user) {
			this.loadDashboardData()
		}

		this.getUser();
		// console.log(this)
	}

	api_error = () => {
		if (window.confirm('There were some error loading this page. Please refresh')) {
			window.location.reload()
		}
	}

	loadDashboardData = async () => {
		this.setState({ ...this.state, loading: true })
		try {
			let req = await fetch(`/api/dashboard`, { method: 'POST' })
			let res = await req.json()
			this.setState({ ...this.state, ...res, loading: false });
		} catch (err) {
			this.setState({ ...this.state, loading: false });
			this.api_error()
			console.log(err)
		}
	}

	deleteActivity = async (activity) => {
		let toastID = toast.loading('Deleting activity...')
		let req = await fetch(`/api/dashboard/activity/${activity._id}`, { method: 'DELETE' })
		let res = await req.json()
		let msg = { render: 'Activity Deleted', type: 'success' }
		if (res.activity._id != activity._id) {
			msg = { render: 'An error occured while deleting activity', type: 'error' }
		}

		let recentActivities = this.state.dashboardData.recentActivities.filter(act => act._id != activity._id)
		let activities = this.state.activities
		if (activities.length > 0) {
			activities = activities.filter(act => act._id != activity._id)
		}
		this.setState({ ...this.state, activities, dashboardData: { ...this.state.dashboardData, recentActivities } })
		toast.update(toastID, { ...toastOptions, ...msg })
	}

	sendMessage = async (e) => {
		e.preventDefault()
		this.setState({ ...this.state, sendingMessage: true })
		let toastID = toast.loading('Sending message...')
		let req = await fetch(`/api/dashboard/message/create`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ message: e.target.message.value })
		})
		let res = await req.json()
		let recentMessages = [res.msg, ...this.state.dashboardData.recentMessages]
		let messages = [res.msg, ...this.state.messages]

		this.setState({ ...this.state, messages, dashboardData: { ...this.state.dashboardData, recentMessages }, sendingMessage: false })
		toast.update(toastID, { ...toastOptions, render: 'Message Sent', type: 'success' })
		e.target.reset()
	}

	deleteMessage = async (message) => {
		let toastID = toast.loading('Deleting message...')
		let req = await fetch(`/api/dashboard/message/${message._id}`, { method: 'DELETE' })
		let res = await req.json()

		let toastRes = { render: 'message Deleted', type: 'success' }
		if (res.msg._id != message._id) {
			toastRes = { render: 'An error occured while deleting message', type: 'error' }
		}

		let recentMessages = this.state.dashboardData.recentMessages.filter(msg => msg._id != message._id)

		let messages = this.state.messages
		if (messages.length > 0) {
			messages = messages.filter(msg => msg._id != message._id)
		}
		this.setState({ ...this.state, messages, dashboardData: { ...this.state.dashboardData, recentMessages } })
		toast.update(toastID, { ...toastOptions, ...toastRes })
	}

	requestHelp = async (e) => {
		e.preventDefault()

		let body = JSON.stringify({ subject: e.target.subject.value, description: e.target.description.value })
		let toastID = toast.loading('Sending Email...')
		let req = await fetch(`/api/dashboard/requestHelp`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			},
			body
		})
		let res = await req.json()
		toast.update(toastID, { ...toastOptions, render: res.text, type: res.res })
	}

	loadAllActivities = async () => {
		this.setState({ ...this.state, loading: true })
		let req = await fetch(`/api/dashboard/activity/list`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			}
		})
		let res = await req.json()
		this.setState({ ...this.state, activities: res.activities, loading: false })
	}

	unloadAllActivities = () => {
		this.setState({ ...this.state, activities: [], loading: false })
	}

	loadAllMessages = async () => {
		this.setState({ ...this.state, loading: true })
		let req = await fetch(`/api/dashboard/message/list`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			}
		})
		let res = await req.json()
		this.setState({ ...this.state, messages: res.messages, loading: false })
	}

	unloadAllMessages = () => {
		this.setState({ ...this.state, messages: [], loading: false })
	}

	loadReports = async (report_from, report_to, report_type) => {
		this.setState({ ...this.state, loading: true })
		console.log("report_from: ", dateFormat(report_from, "yyyy-mm-dd"));
		console.log("report_to: ", report_to)
		let reportdata = { report_from: dateFormat(report_from, "yyyy-mm-dd"), report_to: dateFormat(report_to, "yyyy-mm-dd"), report_type }
		let req = await fetch(`/api/dashboard/reports`, {
			method: 'POST',
			body: JSON.stringify(reportdata),
			headers: {
				'content-type': 'application/json'
			}
		})

		let res = await req.json();
		console.log("reports", res)
		return res.reports;
	}

	unloadReports = () => {
		this.setState({ ...this.state, reports: [], loading: false })
	}

	render() {
		return (
			<DashboardContext.Provider value={this.state}>
				{this.props.children}
			</DashboardContext.Provider>
		);
	}
}

export { DashboardContext, DashContextProvider };
